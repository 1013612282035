

//prod endpoints
/*
//export const RUS_URL =   "https://api-gateway.rallyhood.com/user/users"
export const RUS_URL = "https://93mk36vnjc.execute-api.us-east-1.amazonaws.com/production/user/users"
export const RAS_URL = "https://api-gateway.rallyhood.com/auth/tokens";
export const RH_URL = "https://www.rallyhood.com/"
//export const RCS_URL = "https://api-gateway.rallyhood.com/content/contents";
export const RCS_URL = "https://inn9x494cf.execute-api.us-east-1.amazonaws.com/production/contents";
//export const RMEMS_URL = "https://api-gateway.rallyhood.com/membership/memberships";
export const RMEMS_URL = "https://qotwigb3a8.execute-api.us-east-1.amazonaws.com/production/memberships";
//export const RRS_URL = "https://api-gateway.rallyhood.com/rally";
export const RRS_URL = "https://8lio221lpi.execute-api.us-east-1.amazonaws.com/production";
//export const RCHANS_URL = "https://api-gateway.rallyhood.com/channel/channels";
export const RCHANS_URL = "https://62fql7icai.execute-api.us-east-1.amazonaws.com/production/channels"
export const RCALS_URL = "https://api-gateway.rallyhood.com/calendar/calendars"
//export const PARTS_URL = "https://api-gateway.rallyhood.com/content/participations"
export const PARTS_URL = "https://inn9x494cf.execute-api.us-east-1.amazonaws.com/production/content"
*/

//updatethis
//test against prod from local
export function HB_LOGGING() {return false}
export function IS_STAGING() { return true }
//export function IS_STAGING() { return (window.location.href.indexOf("staging") > -1 || window.location.href.indexOf("localhost") > -1) }
export function STRIPE_PUBLISHABLE_KEY() { return IS_STAGING() ? "pk_test_51DLYQSF3WHnNQL2Jfw7ewSqfpFOKJqc8we7lWdT5QBkKgYSNKaJevvccsF4Ig98Eq3qvd3hsGzgz9l3D47n3VDCY00bcBvrsOy" : "pk_live_iA3gTzdoHmjEIxpOd5X5wiDv"}
export function CID() { return IS_STAGING() ? "0d3b7eb3d3c9101c3078685d3abc15efb0c64db1084f78de7dda4abf4e1702fe" : "18CADF922A83B53958B5838B23BDA40A984F404C1AD5F57A1A3D337FB7EB35C4"}
export function RAFS_URL()  { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/feed/activity-feeds" : "https://api-gateway.rallyhood.com/feed/activity-feeds"}
export function RFINS_URL()  {return IS_STAGING() ?  "https://apig.staging.rallyhood.com/finance/" : "https://api-gateway.rallyhood.com/finance/" }
export function RFINS_STRIPE_TRANSACTION_URL()  {return IS_STAGING() ?  "https://apig.staging.rallyhood.com/finance/stripe-transactions/" : "https://api-gateway.rallyhood.com/finance/stripe-transactions/" }
export function RFINS_FUNDRAISER_DETAILS_URL() {return IS_STAGING() ?  "https://apig.staging.rallyhood.com/finance/fundraiser-details/" :  "https://api-gateway.rallyhood.com/finance/fundraiser-details/" }
export function RFINS_ACCOUNT_RALLY_URL() {return IS_STAGING() ?  "https://apig.staging.rallyhood.com/finance/payment-accounts/" :  "https://api-gateway.rallyhood.com/finance/payment-accounts/" }
export function RSENTS_URL() {return  IS_STAGING()  ?  "https://apig.staging.rallyhood.com/sentiment/sentiments":  "https://api-gateway.rallyhood.com/sentiment/sentiments"}
export function RPNS_URL()  {return IS_STAGING() ? "https://apig.staging.rallyhood.com/notification/devices" : "https://api-gateway.rallyhood.com/notification/devices"}
export function RCS_URL() {return IS_STAGING() ?  "https://apig.staging.rallyhood.com/content/contents" : "https://api-gateway.rallyhood.com/content/contents"}
export function RCS_SHARE_URL() {return IS_STAGING() ?  "https://apig.staging.rallyhood.com/content/shares/" : "https://api-gateway.rallyhood.com/content/shares/"}
export function RCS_PROMOTE_URL() { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/content/promotes/" : "https://api-gateway.rallyhood.com/content/promotes/"}
export function RCS_REPORTS_URL() {return IS_STAGING() ?  "https://apig.staging.rallyhood.com/content/reports" : "https://api-gateway.rallyhood.com/content/reports"}

export function RMEMS_URL() { return  IS_STAGING() ?  "https://apig.staging.rallyhood.com/membership/memberships":  "https://api-gateway.rallyhood.com/membership/memberships"}
export function RMEMS_REPORTS_URL() { return  IS_STAGING() ?  "https://apig.staging.rallyhood.com/membership/reports":  "https://api-gateway.rallyhood.com/membership/reports"}
export function RAS_USER_URL() { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/auth/users" : "https://api-gateway.rallyhood.com/auth/users"}
export function RAS_URL() { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/auth/tokens" : "https://api-gateway.rallyhood.com/auth/tokens"}

export function RAS_PW_URL() { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/auth/passwords" : "https://api-gateway.rallyhood.com/auth/passwords"}

export function RAS_MASQUERADE_URL() { return IS_STAGING() ? "https://apig.staging.rallyhood.com/auth/masquerades" : "https://api-gateway.rallyhood.com/auth/masquerades"}
export function RRS_URL() { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/rally" : "https://api-gateway.rallyhood.com/rally"}
export function RCHANS_URL() { return  IS_STAGING() ?  "https://apig.staging.rallyhood.com/channel/channels" : "https://api-gateway.rallyhood.com/channel/channels" }
export function RCHAN_KEYS_URL() { return  IS_STAGING() ?  "https://apig.staging.rallyhood.com/channel/channel_keys" : "https://62fql7icai.execute-api.us-east-1.amazonaws.com/production/channel_keys"} //"https://api-gateway.rallyhood.com/channel_keys" }//
export function RCALS_URL() { return  IS_STAGING() ?  "https://apig.staging.rallyhood.com/calendar/calendars" : "https://api-gateway.rallyhood.com/calendar/calendars"}
export function PARTS_URL() { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/content/participations" : "https://api-gateway.rallyhood.com/content/participations"}
export function RLOCS_URL()  { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/location" : "https://api-gateway.rallyhood.com/location"}
export function RNS_URL()  { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/activity" : "https://api-gateway.rallyhood.com/activity"}
export function RNS_COUNTS_URL()  { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/activity" : "https://api-gateway.rallyhood.com/activity"}
export function RTAGS_URL()  { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/tag" : "https://api-gateway.rallyhood.com/tag"}

export function RUS_URL()  { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/user/users" : "https://api-gateway.rallyhood.com/user/users"}
export function RUS_EMAILS_URL() { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/user/user-emails" : "https://api-gateway.rallyhood.com/user/user-emails"}
export function RUNS_URL()  { return IS_STAGING() ?  "https://apig.staging.rallyhood.com/usernotification" : "https://api-gateway.rallyhood.com/usernotification"}
export function RH_URL() {return IS_STAGING() ?  "https://stagingrails.rallyhood.com/" :  "https://do-good.rallyhood.com/"}
export function RH_MARKETING_URL() {return IS_STAGING() ?  "https://stagingrails.rallyhood.com/" :  "https://do-good.rallyhood.com/"}
export function RH_VUE_URL() {return IS_STAGING() ?  "https://staging.rallyhood.com/" :  "https://rallyhood.com/"}
//test password reset locally...
//export function RH_URL() { return "http://192.168.86.234:3000/"}


// --- LOCAL URLS --------------

//export function RCS_URL() {return "http://localhost:3020/api/v1/contents"}
//export function RFINS_URL() { return "http://localhost:3333/api/v1" }
//export function RFINS_STRIPE_TRANSACTION_URL() {return "http://localhost:3333/api/v1/stripe_transactions/"}
//export function RFINS_FUNDRAISER_DETAILS_URL() { return"http://localhost:3333/api/v1/fundraiser_details/"}
//export function RFINS_ACCOUNT_RALLY_URL() { return "http://localhost:3333/api/v1/accounts/"}

//export function RNS_URL()  { return "http://localhost:3013/api/v2"}
//export function RNS_COUNTS_URL()  { return "http://localhost:3013/api/v2/activities"}
//export function RUNS_URL()  { return "http://localhost:3033/api/v1"}
//export function RAS_URL() { return "http://localhost:3130/api/v1/tokens"}
//export function RAS_PW_URL() { return "http://localhost:3332/api/v1/passwords"}
//export function RAS_USER_URL() { return "http://localhost:3130/api/v1/users" }

//export function RMEMS_URL() {return "http://localhost:3030/api/v1/memberships"}
//export function RMEMS_REPORTS_URL() {return "http://localhost:3030/api/v1/reports"}
//export function RCS_REPORTS_URL() {return "http://localhost:3020/api/v1/reports"}
//export function PARTS_URL() {return "http://localhost:3020/api/v1/participations"}

//export function RCALS_URL() { return "http://localhost:3001/v3/calendars"}
//export function RUS_URL() {return "http://localhost:3120/api/v1/users"}
//export function RUS_EMAILS_URL() {return "http://localhost:3120/api/v1/user_emails"}
//export function RCHANS_URL() {return "http://localhost:3099/api/v1/channels"}
//export function RCS_URL() {return "http://localhost:3020/api/v1/contents"}
//export function RCS_SHARE_URL() { return "http://localhost:3020/api/v1/shares/"}
//export function RCS_PROMOTE_URL() { return "http://localhost:3020/api/v1/promotes" }
//export function RCS_REPORTS_URL() { return "http://localhost:3020/api/v1/reports.xls" }
//export function RRS_URL() {return "http://localhost:3080/api/v1"}
//export function RSENTS_URL() {return "http://localhost:3190/api/v1/sentiments"}


//export function RH_URL() {return "http://localhost:3000/"}
//export function RH_MARKETING_URL() {return "http://localhost:3000/"}
//export function RAS_URL() { return "http://localhost:3332/api/v1/tokens"}
//export function RAFS_URL() {return "http://localhost:3034/api/v1/activity_feeds"}

/*

export function RAS_URL() { return "http://localhost:3332/api/v1/token"}
export function RUS_URL() {return "http://localhost:3120/api/v1/users"}

export function RMEMS_URL() {return "http://localhost:3030/api/v1/memberships"}

export function RCHANS_URL() {return "http://localhost:3098/api/v1/channels"}
export function RPNS_URL() {return "http://localhost:3113/api/v1/devices/register"}
export function RSENTS_URL() {return "http://localhost:3331/api/v1/sentiments"}
export function RCALS_URL() { return "http://localhost:3001/v3/calendars"}
*/

////export const RPNS_URL = "http://rpns.staging.rallyhood.com/api/v1/devices/register";


