<template>
  <div class="move-modal" v-if="visible">
    <div class="move-modal-content">
      <h2>Select a Folder</h2>
      <select v-model="selectedFolderId" ref="targetFolder">
        <option value="">-- Select Folder --</option>
        <option v-for="folder in nestedFoldersWithOptions" :key="folder.id" :value="folder.id">
          {{ folder.title }}
        </option>
      </select>
      <div class="modal-buttons">
        <button class="cancel-button" @click="cancel">Cancel</button>
        <button class="save-button" @click="save">Save</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MoveModal',
  props: {
    channelId: String,
    visible: {
      type: Boolean,
      required: true
    },
    nestedFolders: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    nestedFoldersWithOptions() {
      return this.flattenNestedFolders(this.nestedFolders);
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close');
    },
    indentFolderTitle(title, nestLevel) {
      const indentation = " ".repeat(nestLevel * 2);
      return `${indentation}${title}`;
    },
    flattenNestedFolders(folders, prefix = ' -') {
      let result = [];
      for (const folder of folders) {
        if (folder.attributes.editable && folder.attributes.type == "Folder"){
          if(folder.attributes.title === "** ROOT FOLDER **"){
            result.push({ id: folder.id, title: folder.attributes['title'] });
          } else {
            result.push({ id: folder.id, title: prefix + ' ' + folder.attributes['title'] });
          }
          if (folder.children?.length > 0) {
            result = result.concat(this.flattenNestedFolders(folder.children, prefix + '-'));
          } 
        }
      }
      return result;
    },
    cancel() {
      this.$emit('close');
    },
    save() {
      const selectedIndex = this.$refs.targetFolder.selectedIndex;
      const destinationFolderId = this.selectedFolderId;
      let contextType = 'Container'
      //if (selectedIndex === 1) {
        //contextType = 'Channel'
      //}
      this.$emit('move', {destinationFolderId, contextType});
    },
  }
};
</script>

<style scoped>
.move-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; /* Set a higher z-index value */
}

.move-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.actions {
  margin-top: 10px;
}

.modal-buttons {
  margin-top: 10px;
}

.cancel-button {
  margin-right: 10px;
}

.save-button {
  margin-left: 10px;
}
</style>
