<script>
import { router, findDefaultChannelOfTypeForRally, findDefaultChannelObjOfType, avaUrl } from "../common";
import ContentFlagging from "./ContentFlagging";
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState("memberships", ["myMembershipByRallyId"]),
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("rallies", ["allRalliesByRallyId", "rallyChannelsByRallyId"]),
    ...mapState("shares", ["sharesByContentId", "sharesByShareId"]),
    ...mapState("channels", ["channelsById"]),
    contentShares(){
        return this.sharesByContentId[parseInt(this.content.id)]
    },
    isAdmin() {
      return this.allRalliesByRallyId[this.$route.params.rallyId].rally.attributes["editable"]
    },
    isRallyWritable() {
      return this.allRalliesByRallyId[this.$route.params.rallyId].rally.attributes["writable"]
    },
    isOwner() {
      return this.myRallyMembership.attributes["role"] == "Owner"
    },
    myRallyMembership(){
      return this.myMembershipByRallyId[this.$route.params.rallyId];
    },
    selectedRally: function() {
      return this.allRalliesByRallyId[parseInt(this.$route.params.rallyId)];
    },
    myRole() {
      return this.selectedRally.membership ? this.selectedRally.membership.attributes["role"] : "Friend";
    },
    userIsOwnerComputed(){
      return this.userIsOwner(this.content)
    },
    canDeleteComputed(){
      return this.canDelete(this.content)
    },
    canEditComputed(c){
      return this.canEdit(this.content)
    },
    canUnshareComputed() {
      return this.canUnshare()
    }
  },
  methods: {


    findContentShareByContextRallyId(contextRallyId) {
      // Convert contextRallyId to numeric (integer) if it's a string
      const numericContextRallyId = parseInt(contextRallyId, 10);

      // Find the object with matching 'context-rally-id'
      const foundContentShare = this.contentShares?.find((contentShare) => {
        const contentShareContextRallyId = contentShare.attributes['context-rally-id'];
        return contentShareContextRallyId == numericContextRallyId;
      });

      return foundContentShare;
    },
    originatingRally(rallyId) {
      // --- This is the rally where the content was originally posted
      const contentShare = this.findContentShareByContextRallyId(rallyId)

      return this.originatingRallyShare(contentShare)
    },

    isDefinitelyNotOriginating(rallyId){
      //want to definitively know...if shares are loaded and this isn't the original 
      const contentShare = this.findContentShareByContextRallyId(rallyId)
      return contentShare && !this.originatingRallyShare(contentShare)
    },
    originatingRallyShare(contentShare) {
      // --- This is the rally where the content was originally posted
      return (contentShare && contentShare.attributes['share-type'] === 'original')
    },
    userIsOwner(c){
      return (c && c.attributes && c.attributes["creator-id"] == this.user.user_id) 
    },
    canDelete(){
       return ((this.content && this.content.attributes.deletable !== undefined) ?  (this.content.attributes.deletable) : (this.userIsOwner || this.isAdmin))
    },
    canEdit(){
      //return this.userIsOwner || this.isAdmin
      return ((this.content && this.content.attributes.editable !== undefined) ?  this.content.attributes.editable : (this.userIsOwner || this.isAdmin))
    },
    canUnshare(){
      let share = this.sharesByShareId[this.shareId]
      let channel = this.channelsById[this.channelId]
      if(share && share.attributes){
        return (this.isDefinitelyNotOriginating(this.$route.params.rallyId)) && (share.attributes['creator-id'] == this.user.user_id || this.isAdmin || this.isRallyWritable || channel.attributes.editable || channel.attributes.writable)
      } else {
        return false
      }
    },
  }
};
</script>
